import { Heading, Container, Page } from '@troon/ui';
import { For } from 'solid-js';
import { Meta, Title } from '@solidjs/meta';
import { NoHydration } from 'solid-js/web';
import { getConfigValue } from '../../modules/config';
import { Hero } from '../../components/hero/photo';
import { Benefit } from '../../components/benefit';
import type { RouteDefinition } from '@solidjs/router';
import type { ComponentProps } from 'solid-js';

export default function PlayTroonProgramsPage() {
	return (
		<NoHydration>
			<Title>Play Troon Programs | Troon</Title>
			<Meta
				name="description"
				content="Troon is dedicated to growing the game of golf and has implemented initiatives and programs to help you and your family play more. Check out all of the #PlayTroon opportunities below."
			/>
			<Hero src={`${getConfigValue('IMAGE_HOST')}/digital/hero/play-troon-programs.jpeg`}>
				<Heading as="h1" class="text-center ">
					Play Troon Programs
				</Heading>
				<p>
					Troon is dedicated to growing the game of golf and has implemented initiatives and programs to help you and
					your family play more. Check out all of the #PlayTroon opportunities below.
				</p>
			</Hero>

			<Container>
				<Page>
					<div class="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8 xl:grid-cols-3">
						<For each={programs}>{(program) => <Benefit {...program} />}</For>
					</div>
				</Page>
			</Container>
		</NoHydration>
	);
}

export const route = { info: { hasHero: true } } satisfies RouteDefinition;

const programs: Array<ComponentProps<typeof Benefit>> = [
	// {
	// 	image: `${getConfigValue('IMAGE_HOST')}/digital/logos/prive.png`,
	// 	title: 'Troon Privé',
	// 	content: 'Qualified members of Troon Privé clubs receive exclusive access to global golf and lifestyle benefits!',
	// 	linkText: (
	// 		<>
	// 			Learn more<span class="sr-only"> about Troon Privé</span>
	// 		</>
	// 	),
	// 	url: '/member-programs',
	// },
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/troon-junior-club.png`,
		title: 'Troon Junior Club',
		content:
			'Troon is dedicated to growing the game with Junior Golfers, which is why Troon is pleased to offer the new Troon Junior Club program that builds upon the success of our already popular Troon Family Golf program.',
		linkText: (
			<>
				Learn more<span class="sr-only"> about Troon Junior Club</span>
			</>
		),
		url: '/play-troon-programs/troon-junior-club',
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/troon-family-golf.jpg`,
		title: 'Troon Family Golf',
		content:
			'Troon is leading the way with introducing juniors to the world’s greatest game 365 days per year. The offer is simple and affordable!',
	},
	// {
	// 	image: `${getConfigValue('IMAGE_HOST')}/digital/logos/troons-troops.jpg`,
	// 	title: 'Troon’s Troops',
	// 	content:
	// 		'The Troon’s Troops program has an exciting enhancement! In addition to the current offering of Troon Card rates to active duty military and severely injured U.S. combat veterans, Troon has launched the Patriot Troon Card.',
	// },
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/troon-fit-2024.jpg`,
		title: 'TroonFIT',
		content:
			'Troon is committed to integrating and leading fit and healthy lifestyles for members, guests and associates alike.',
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/troon-values-your-time.jpg`,
		title: 'Troon Values Your Time',
		content:
			'Troon has introduced a plan to ensure that every golfer at every Troon-managed facility has an enjoyable golf experience while playing in a reasonable amount of time.',
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/troon-challenge.jpg`,
		title: 'Troon Challenge',
		content:
			'Troon invites you to experience great golf combined with friendly competition at the Troon Challenge, a series of amateur golf tournaments contested at several of the world’s finest golf courses.',
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/troon-golf-academy.jpg`,
		title: 'Golf Schools',
		content: 'You deserve world-class golf instruction from the Troon Golf – Academy and Troon Golf schools.',
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/get-into-golf.jpg`,
		title: 'Get Into Golf',
		content:
			'Been looking for an affordable opportunity to start playing golf…We’ve got you covered! Troon invites you to Get Into Golf! Starting at only $39, select Troon courses will provide you with the tools and instruction you need to start playing golf today.',
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/womens-golf-month.jpg`,
		title: 'Troon Women’s Golf Month',
		content:
			'The entire month of June is Troon Women’s Golf Month! While Troon focuses on growing participation among all genders and age groups throughout the year, Troon facilities, Troon Golf Academies and their respective teaching professionals are joining together to host introductory golf clinics, fun social events, and special playing opportunities all month long.',
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/player-development-month.jpg`,
		title: 'Troon Player Development Month',
		content:
			'The entire month of May is Troon’s Player Development Month! Troon facilities and Troon Golf Academies around the world will be hosting introductory golf clinics all month long! These clinics are designed to introduce juniors and beginners to the game in a fun and educational setting.',
	},
];
